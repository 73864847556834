export const doctorRoutes = {
  home: '/',
  welcome: '/welcome',
  onboarding: '/onboarding',
  onboardingOthers: '/onboarding-others',
  createaccount: '/createaccount',
  signin: '/login',
  forgotpassword: '/forgotpassword',
  rxgroup: '/rxgroup',
  viewDrug: '/rxgroup/viewDrug',
  profile: '/profile',
  reportviewsummary: '/reportviewsummary',
  cancelappointment: '/cancelappointment',
  summary: '/summary',
  viewReportPage: '/viewReportPage',
  availability: '/availability',
  resetpassword: '/resetpassword',
  verification: '/verification',
  googleintegeration: '/google_calender_integeration',
  referralsuccess: '/referralsuccess',
  Prescription: '/prescription',
  doctorChatPage: '/doctorChat',
  profileMobilePage: '/profileMobilePage',
  doctorChatStatus: '/doctorChatStatus',
};

// 2498  eq5nhc
