/* eslint-disable react/jsx-key */
import { Button } from '@atoms';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toggleButton_style } from './style';

export function ToggleButton(props) {
  const {
    className = '',
    onChange = () => {},
    value,
    data,
    options = [],
    isError = false,
    errorMessage = '',
    disabled = false,
    buttonactiveStyle = {},
    buttoninactiveStyle = {},
    RootStyle = {},
    dataTestId = '',
    ...rest
  } = props;
  const [active, setActive] = useState(value);
  const [name, setName] = useState(data);

  useEffect(() => {
    if (value !== active) {
      onChange(active, name);
    }
  }, [active]);

  useEffect(() => {
    setActive(value);
  }, [value]);

  return (
    <Box>
      <Box
        gap={1}
        sx={{ my: 0.5, display: 'flex', opacity: disabled ? 0.5 : 1, ...RootStyle }}
        className={`${className}`}
        
        {...rest}
      >
        {options?.map((val, i) => (
          <Box
            id={`${dataTestId}${i + 1}`}
            key={i}
            sx={{
              display: 'grid',
              placeItems: 'center',
              gap: 0.5,
              ...(val.value === active
                ? toggleButton_style.activeSx
                : toggleButton_style.radioSx),
            }}
            onClick={() => {!disabled &&
              setActive(val.value);
              setName(val);
            }}
          >
            <Button
              size="small"
              id={val?.name ? val?.name : ''}
              sx={
                val.value === active
                  ? {
                      ...toggleButton_style.buttonactiveSx,
                      ...buttonactiveStyle,
                    }
                  : {
                      ...toggleButton_style.buttoninactiveSx,
                      ...buttoninactiveStyle,
                    }
              }
            >
              {val?.name ? val?.name : ''}
            </Button>
          </Box>
        ))}
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

ToggleButton.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  buttonactiveStyle: PropTypes.object,
  buttoninactiveStyle: PropTypes.object,
  RootStyle: PropTypes.object,
  value: PropTypes.any,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
  dataTestId: PropTypes.string,
  data: PropTypes.any,
};
