/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import {
  getTimeZoneFnc,
  getTodayDate,
  getDateFormat,
  getDateFormatWithoutDate,
} from '@hc/dayjs';
import { log } from '@hc/logger';
import { doctorRoutes } from '@hc/routes';
import {
  formatDate,
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
  routeTo,
  twentyFourTimeFormat,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const constructReasonData = (data) => {
  const reasonData = data.map((val) => ({
    value: val?.id,
    label: val?.reason ?? '',
  }));
  return reasonData;
};

export const constructDoctorData = (data,type) => {
  const reasonData = data.map((val) => ({
    value:  val?.user_profile_id,
    label: val?.name ?? '',
  }));
  return reasonData;
};

export const usePatientDetails = create((set, get) => ({
  patientState: {
    appointmentCurrentDate: new Date(),
    patientData: [],
    patientDataIntialState: [],
    doctorAvailableDetails: [],
    filter: 'A-Z',
    startDate: '',
    endDate: '',
    timeZone: getTimeZoneFnc(),
    morningSlotData: [],
    afternoonSlotData: [],
    eveningSlotData: [],
    nightSlotData: [],
    selectedDate: getDateFormatWithoutDate('YYYY-MM-DD'),
    selectedSlot: '',
    selectedSlotStartTime: '',
    selectedSlotEndTime: '',
    calenderData: {
      startofWeek: '',
      endofWeek: '',
      betweenDates: [],
    },
    doctorSearch: '',
    languages: [],
    doctorDetail: {},
    doctorAvailableDetailsCopy: null,
  },
  doctorSlots: [],
  specialityMasterData: [],
  dotorList: [],
  reasonData: [],
  verifiedDoctors: [],
  loading: true,

  updateBAState: (key, value) => {
    const { patientState } = get();

    set({
      patientState: {
        ...patientState,
        [key]: value,
      },
    });
    // }
  },

  selectSlotUpdate: async (slotStartTime, slotEndTime, slot) => {
    const { patientState } = get();
    set({
      patientState: {
        ...patientState,
        selectedSlotStartTime: slotStartTime,
        selectedSlotEndTime: slotEndTime,
        selectedSlot: slot,
      },
    });
  },

  getPatientData: async (key) => {
    try {
      const { patientState } = get();
      const { appointmentCurrentDate, patientData } = patientState;
      const utcDate = new Date(appointmentCurrentDate).toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      set({ loading: true });
      const payload = {
        doctor_id: keydata?.user_id,
        search: '',
        sort_by: '',
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['getPatient', utcDate],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/patients/get`,
            {
              ...payload,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      return set(() => ({
        loading: false,
        patientState: {
          ...patientState,
          patientData: data?.patients,
          patientDataIntialState: data?.patients,
        },
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  setBookAppoinment: async (payload) => {
    try {
      const { patientState } = get();
      const { appointmentCurrentDate, timeZone } = patientState;
      const utcDate = new Date().toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      set({ loading: true });

      toast.loading('Loading...');

      const payloadData = {
        user_profile_id: payload?.user_profile_id ?? '',
        appointed_doctor_details: {
          id: payload?.appointed_doctor_details?.id ?? '',
          email: keydata?.email_id ?? '',
          mobile: `${keydata?.country_code} ${keydata?.mobile_no}`,
          appointment_date:
            payload?.appointed_doctor_details?.appointment_date ?? '',
          appointment_time_slot:
            payload?.appointed_doctor_details?.appointment_time_slot ?? '',
          sendInvite: payload?.appointed_doctor_details?.sendInvite ?? '',
          doctor_name: payload?.appointed_doctor_details?.doctor_name ?? '',
          appointment_client_start_time:
            payload?.appointed_doctor_details?.appointment_client_start_time ??
            '',
          appointment_client_end_time:
            payload?.appointed_doctor_details?.appointment_client_end_time ??
            '',
          reason_id: payload?.appointed_doctor_details?.reason_id,
          reason: payload?.appointed_doctor_details?.reason,
        },
        clientTimeZone: timeZone,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['bookAppoinment', utcDate, payload],
        queryFn: async () => {
          const { data, status } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/book-appointment`,
            {
              ...payloadData,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        toast.dismiss();
        toast.success(status?.message);
      }

      set({
        loading: false,
      });
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getDoctorSlot: async (dateF) => {
    try {
      const { patientState } = get();
      const { timeZone } = patientState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      const start_Date = new Date(dateF.valueOf() - 1000 * 60 * 60 * 24);

      // set({ loading: true });

      const key = formatDate(dateF.toDateString());

      const payloadData = {
        dates: [`${formatDate(dateF.toDateString())}`],
        startDateTime: `${formatDate(
          start_Date.toDateString(),
        )}${'T18:30:00.000Z'}`,
        endDateTime: `${formatDate(dateF.toDateString())}${'T18:29:59.999Z'}`,
        clientTimeZone: timeZone,
        // search: '',
        // languages: [],
        // speciality: '',
        id: keydata?.profile?.id,
      };

      // Hitting the get doctor lisiting API

      const { data, status } = await queryClient.fetchQuery([
        '/appointments/doctors/availability',
        'post',
        {
          ...payloadData,
        },
      ]);

      if (data[0]?.slots?.[key]) {
        let arr = [];
        let getDate = new Date();
        let currentDate = formatDate(getDate.toDateString());
        let currentTime = `${getDate.getHours()}:${getDate.getMinutes()}:00`;

        data[0]?.slots?.[key].forEach((val, index, array) => {
          if (val?.date !== currentDate) {
            const objVal = twentyFourTimeFormat(val?.slot);
            const obj = {
              label: val?.slot,
              value: objVal,
            };
            arr.push(obj);
          } else if (val?.date === currentDate) {
            if (getTodayDate('HH:mm A') < twentyFourTimeFormat(val?.slot)) {
              const objVal = twentyFourTimeFormat(val?.slot);
              const obj = {
                label: val?.slot,
                value: objVal,
              };
              arr.push(obj);
            }
          }
        });

        if (currentDate <= key) {
          set(() => ({
            loading: false,
            doctorSlots: arr,
          }));
        } else {
          toast.error('Please select current or future date...');
        }
      } else {
        set(() => ({
          loading: false,
          doctorSlots: [],
        }));
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getDoctorData: async (payload, id) => {
    try {
      const { patientState } = get();
      set({ slotLoading: true });
      const payloadData = {
        dates: payload?.dates ?? [],
        startDateTime: payload?.startDateTime ?? '',
        endDateTime: payload?.endDateTime ?? '',
        clientTimeZone: payload?.clientTimeZone ?? '',
        id: payload?.id ? payload?.id : id,
      };
      // Hitting the get doctor lisiting API
      const { data } = await queryClient.fetchQuery([
        '/appointments/doctors/availability',
        'post',
        { ...payloadData },
      ]);

      set(() => ({
        slotLoading: false,
        patientState: {
          ...patientState,
          doctorDetail: data?.[0],
          doctorAvailableDetailsCopy: data,
        },
      }));
      return data?.[0];
    } catch (error) {
      set({ slotLoading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  addNewPatient: async (payload) => {
    try {
      const { patientState } = get();
      const { appointmentCurrentDate } = patientState;
      const utcDate = appointmentCurrentDate.toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);
      set({ loading: true });

      const payloadData = {
        doctor_id: keydata?.user_id,
        name: payload?.name,
        gender: payload?.gender,
        age: payload?.age,
        country_code: payload?.country_code,
        mobile_no: payload?.mobile_no,
      };

      // Hitting the get doctor lisiting API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['add-patient', utcDate, payloadData],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/add-patient`,
            {
              ...payloadData,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      // To invalidate the cache of performanceStats
      queryClient.refetchQueries({
        queryKey: ['getPatient', utcDate],
      });

      queryClient.invalidateQueries({
        queryKey: ['getPatient', utcDate],
      });
      set({ loading: false });

      if (status?.code === 200) {
        toast.success(status?.message);
      }
      return status?.code === 200 &&
        status.message === 'Patient Added & Invited Sucessfully'
        ? true
        : false;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getSpeacialityMasterData: async () => {
    try {
      set({ loading: true });

      const { data } = await queryClient.fetchQuery([
        '/speciality/getAll',
        'get',
      ]);
      return set(() => ({
        loading: false,
        specialityMasterData: data,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      if (error?.message === 'Request failed with status code 401') {
        // toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        return routeTo(useRouting, doctorRoutes.signin);
      }
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  getDocterList: async (value) => {
    try {
      const { patientState } = get();
      const { appointmentCurrentDate } = patientState;
      const utcDate = appointmentCurrentDate.toISOString();
      // set({ loading: true });
      const payloadData = {
        speciality: value,
      };

      // Hitting the get doctor lisiting API

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['DocterList', utcDate, value],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/get-doctor-by-speciality`,
            {
              ...payloadData,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      let arr = [];
      for (const val of data) {
        const obj = {
          label: val?.first_name,
          value: val?.id,
        };
        arr.push(obj);
      }

      set(() => ({
        loading: false,
        doctorList: arr,
      }));
      return arr;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  setRefferalNotes: async (payload) => {
    try {
      const { patientState } = get();
      const { appointmentCurrentDate } = patientState;
      const utcDate = appointmentCurrentDate.toISOString();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);

      set({ loading: true });

      const payloadData = {
        appointment_id: '',
        patient_profile_id: payload?.patient_profile_id ?? '',
        referral_doctor_mobile_no: payload?.referral_doctor_mobile_no ?? '',
        referral_doctor_country_code:
          payload?.referral_doctor_country_code ?? '',
        referral_doctor_name: payload?.referral_doctor_name ?? '',
        speciality_id: payload?.speciality_id ?? '',
        referred_by: keydata?.profile.id ?? '',
        referred_to: payload?.referred_to ?? '',
        referral_notes: payload?.referral_notes ?? '',
      };

      queryClient.invalidateQueries({
        queryKey: ['send-refferals', utcDate],
      });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['send-refferals', utcDate],
        queryFn: async () => {
          const { data, status } = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/send-referral`,
            {
              ...payloadData,
            },
            true,
          );
          return data;
        },
        staleTime: 120000,
      });

      set({
        loading: false,
      });

      if (status?.code === 200) {
        toast.success(status?.message);
      }

      // if(data)
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  updatePatientState: (key, value) => {
    const { patientState } = get();
    set({
      patientState: {
        ...patientState,
        [key]: value,
      },
    });
  },

  setPatientSeachFilter: (value) => {
    const { patientState } = get();
    let patientDataCopy = JSON.parse(
      JSON.stringify(patientState?.patientDataIntialState),
    );

    let Arr = patientDataCopy?.filter(function (val) {
      return val.patient_profile.name.includes(value);
    });

    if (value) {
      set({
        patientState: {
          ...patientState,
          patientData: Arr,
        },
      });
    } else {
      set({
        patientState: {
          ...patientState,
          patientData: patientState?.patientDataIntialState,
        },
      });
    }
  },

  clearDoctorSlots: () => {
    set(() => ({
      doctorSlots: [],
      selectedDate: getDateFormatWithoutDate('YYYY-MM-DD'),
      selectedSlot: '',
      selectedSlotStartTime: '',
      selectedSlotEndTime: '',
      morningSlotData: [],
      afternoonSlotData: [],
      eveningSlotData: [],
      nightSlotData: [],
      appointmentStatusDetails: {},
      calenderData: {
        startofWeek: '',
        endofWeek: '',
        betweenDates: [],
      },
    }));
  },

  claerDoctorDetail: () => {
    const { patientState } = get();
    set({
      patientState: {
        ...patientState,
        doctorDetail: {},
        selectedDate: '',
        selectedSlot: '',
        selectedSlotStartTime: '',
        selectedSlotEndTime: '',
        morningSlotData: [],
        afternoonSlotData: [],
        eveningSlotData: [],
        nightSlotData: [],
        appointmentStatusDetails: {},
      },
    });
  },
  getReasonData: async () => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getReasonData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-reason`,
            {},
            true,
          );
          return response;
        },
      });
      let constructData =
        Array.isArray(data?.data) && data?.data?.length > 0
          ? constructReasonData(data?.data)
          : [];
      if (data?.status?.code === 200) {
        set(() => ({
          reasonData: constructData,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!',
      );
    }
  },

  getVerifiedDoctors: async (type) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const keydata = parseJwt(authToken);
      const payload = {
        user_id: keydata?.user_id,
        search: '',
      };
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getVerifiedDoctors'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/doctor/master-verified-doctors/get/all`,
            {
              ...payload,
            },
            true,
          );
          return response;
        },
      });
      let constructData =
        Array.isArray(data?.data) && data?.data?.length > 0
          ? constructDoctorData(data?.data,type)
          : [];
      if (data?.status?.code === 200) {
        set(() => ({
          verifiedDoctors: constructData,
          doctorAvailableDetails: data,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!',
      );
    }
  },

  setStartEndBetween: (start, end, betweenDates) => {
    const { patientState } = get();
    set({
      patientState: {
        ...patientState,
        calenderData: {
          startofWeek: start,
          endofWeek: end,
          betweenDates: betweenDates?.map((i) => i.toISOString()),
        },
      },
    });
  },

  constructSlotDataState: (seDate, value) => {
    const { patientState } = get();
    const { doctorDetail } = patientState;
    const bookAppointmentStateCopy = JSON.parse(JSON.stringify(patientState));
    bookAppointmentStateCopy.morningSlotData = [];
    bookAppointmentStateCopy.afternoonSlotData = [];
    bookAppointmentStateCopy.eveningSlotData = [];
    bookAppointmentStateCopy.nightSlotData = [];
    if (value && value?.id?.length > 0) {
      if (
        Array.isArray(value?.slots?.[seDate]) &&
        value?.slots?.[seDate]?.length > 0
      ) {
        value?.slots?.[seDate].map((val, i) => {
          if (val?.slot) {
            const hours = new Date(val?.client_date_time).getHours();
            if (hours <= 11) {
              bookAppointmentStateCopy.morningSlotData.push(val);
            } else if (hours > 11 && hours <= 15) {
              bookAppointmentStateCopy.afternoonSlotData.push(val);
            } else if (hours > 15 && hours <= 18) {
              bookAppointmentStateCopy.eveningSlotData.push(val);
            } else {
              bookAppointmentStateCopy.nightSlotData.push(val);
            }
          }
        });
      }
    } else if (
      Array.isArray(doctorDetail?.slots?.[seDate]) &&
      doctorDetail?.slots?.[seDate]?.length > 0
    ) {
      doctorDetail?.slots?.[seDate].map((val, i) => {
        if (val?.slot) {
          const hours = new Date(val?.client_date_time).getHours();
          if (hours <= 11) {
            bookAppointmentStateCopy.morningSlotData.push(val);
          } else if (hours > 11 && hours <= 15) {
            bookAppointmentStateCopy.afternoonSlotData.push(val);
          } else if (hours > 15 && hours <= 18) {
            bookAppointmentStateCopy.eveningSlotData.push(val);
          } else {
            bookAppointmentStateCopy.nightSlotData.push(val);
          }
        }
      });
    }
    set({
      patientState: {
        ...patientState,
        ...JSON.parse(JSON.stringify(bookAppointmentStateCopy)),
      },
    });
  },
}));
